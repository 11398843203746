<!-- eslint-disable -->
<template>
  <div
    class="flex w-32 cursor-pointer select-none items-center gap-2 rounded border border-gray-700 px-2 py-1.5 text-xs"
    tabindex="0"
    @click="toggleOpen"
    @keydown.enter="toggleOpen"
    @keydown.space="toggleOpen"
  >
    <MagnifyingGlassIcon class="h-4 w-4 text-gray-400" />
    <span class="text-gray-400">Search</span>
    <span class="ml-auto rounded border border-gray-700 bg-gray-800 px-0.5 text-xxs">{{
      isMac ? '⌘ K' : 'Ctrl K'
    }}</span>
  </div>
  <BaseDialog :model-value="open" size-class="max-w-3xl h-[80vh]" @update:model-value="toggleOpen">
    <BaseSearchbox
      ref="searchbox"
      type="search"
      class="w-full pb-4"
      placeholder="Start by using search shortcuts e.g. /c Bitcoin"
      v-model="currentSearch"
    />
    <div class="flex flex-col gap-2 overflow-auto" :class="selectedCollection ? 'flex-1' : ''">
      <GlobalSearchCollection
        v-if="showRecentSearches"
        :hits="recentSearches"
        collection="recent_searches"
        :count="recentSearches.length"
        @delete="deleteRecentSearch"
        @select="handleSelection"
      >
        <template #default>Recently Searched</template>
      </GlobalSearchCollection>
      <div v-if="anyResults" class="grid gap-2" :class="currentSearch ? 'flex-1 grid-cols-1' : 'grid-cols-2'">
        <template v-for="index in indexSearchResults" :key="index.indexId">
          <GlobalSearchCollection
            v-if="(!selectedCollection || selectedCollection == index.indexId) && index.hits.length > 0"
            :hits="index.hits"
            :collection="index.indexId"
            :count="index.found"
            @refine-next="executeSearch(currentRefinement, $event)"
            @select="handleSelection"
          >
            <template #default>{{ index.label }}</template>
            <template #shortcut>{{ index.shortcut }}</template>
          </GlobalSearchCollection>
        </template>
      </div>
      <div v-else-if="currentSearch" class="flex flex-col items-center gap-4 p-10">
        <p>No results found for "{{ currentSearch }}"</p>
        <BaseButton @press="submitSupportRequest" class="w-48" type="primary" size="md"
          >Request Support For It</BaseButton
        >
      </div>
    </div>
  </BaseDialog>
</template>

<script setup>
import { ref, watch, computed, provide, onMounted, nextTick } from 'vue';
import { useLocalStorage, watchDebounced, watchThrottled, onKeyStroke } from '@vueuse/core';
import useEmitter from '@/composeables/emitter';
import { router } from '@inertiajs/vue3';
import GlobalSearchCollection from '@/components/global_search/GlobalSearchCollection.vue';
import useHttp from '@/composeables/http';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { useThrottleFn } from '@vueuse/core';
import { flashMessage } from '@/composeables/helpers';

const $http = useHttp();
const $emitter = useEmitter();

const props = defineProps({
  typesenseKey: {
    type: String
  },
  typesenseHost: {
    type: String
  }
});

// DIALOG OPEN
const open = ref(false);

const toggleOpen = useThrottleFn(() => {
  open.value = !open.value;
  currentSearch.value = '';
}, 500);

const isMac = ref(navigator.platform.toUpperCase().includes('MAC'));
onKeyStroke('k', event => {
  if (event.metaKey) toggleOpen();
});

// SEARCH FUNCTION
const searchbox = ref(null);
const searchResults = ref([]);
onMounted(executeSearch);

async function executeSearch(q = '', page = 1) {
  const per_page = selectedCollection.value ? 100 : 3;
  const companies_sort = q.length == 0 ? 'name:asc' : '';
  let searches = [
    {
      collection: 'pages',
      query_by: 'name,url',
      sort_by: 'name:asc',
      drop_tokens_threshold: 0,
      q,
      per_page,
      page
    },
    {
      collection: 'coins',
      query_by: 'name,ticker,tab.components,tab.name',
      query_by_weights: '2,2,1,1',
      drop_tokens_threshold: 0,
      sort_by: '_text_match:desc,market_cap:desc',
      q,
      per_page,
      page
    },
    {
      collection: 'companies',
      query_by: 'name,description',
      drop_tokens_threshold: 0,
      sort_by: companies_sort,
      q,
      per_page,
      page
    },
    {
      collection: 'dashboards',
      query_by: 'title,description',
      drop_tokens_threshold: 0,
      q,
      per_page,
      page
    },
    {
      collection: 'widget_templates',
      query_by: 'title,description,tags',
      drop_tokens_threshold: 0,
      q,
      per_page,
      page
    },
    {
      collection: 'datasets',
      query_by: 'title,description,provider',
      drop_tokens_threshold: 0,
      q,
      per_page,
      page
    },
    {
      collection: 'news',
      query_by: 'headline,tags.name',
      drop_tokens_threshold: 0,
      sort_by: 'timestamp:desc',
      q,
      per_page,
      page
    }
  ];

  if (page > 1) {
    searches = searches.filter(s => s.collection == selectedCollection.value);
  }

  const searchResultsResponse = await $http.post(
    `https://${props.typesenseHost}:443/multi_search`,
    { searches },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-TYPESENSE-API-KEY': props.typesenseKey
      }
    }
  );
  const parsedSearchResponse = searchResultsResponse.data.results.map(r => {
    return {
      ...r,
      hits: r.hits.map(h => {
        return { ...h.document, highlight: h.highlight };
      })
    };
  });
  if (page == 1) searchResults.value = parsedSearchResponse;
  else
    searchResults.value = searchResults.value.map(r => {
      return {
        ...r,
        hits:
          r.request_params.collection_name == selectedCollection.value
            ? r.hits.concat(parsedSearchResponse[0].hits)
            : r.hits
      };
    });
}

// SEARCH EVENTS
const currentSearch = ref('');
const currentRefinement = ref('');

watchThrottled(currentSearch, handleSearch, { throttle: 500 });

provide('currentSearch', currentSearch);
provide('currentRefinement', currentRefinement);

async function handleSearch(val) {
  currentSearch.value = val;
  const regexPattern = /\/\w/;
  let foundCollection = null;
  if (regexPattern.test(currentSearch.value)) {
    const shortcut = currentSearch.value.match(regexPattern)[0].trim();
    const indexMapEntry = indicesArray.find(entry => entry.shortcut === shortcut);
    if (indexMapEntry) {
      foundCollection = indexMapEntry.id;
    }
  }
  selectedCollection.value = foundCollection;
  currentRefinement.value = currentSearch.value.replace(regexPattern, '').replace('/', '').trim();
  await executeSearch(currentRefinement.value);
}

// COLLECTIONS
const indicesArray = [
  {
    id: 'coins',
    shortcut: '/c',
    label: 'Coins'
  },
  {
    id: 'companies',
    shortcut: '/x',
    label: 'Companies'
  },
  {
    id: 'news',
    shortcut: '/n',
    label: 'News'
  },
  {
    id: 'dashboards',
    shortcut: '/d',
    label: 'Dashboards'
  },
  {
    id: 'widget_templates',
    shortcut: '/b',
    label: 'Dashboard Components'
  },
  {
    id: 'pages',
    shortcut: '/p',
    label: 'Pages'
  },
  {
    id: 'datasets',
    shortcut: '/s',
    label: 'Datasets'
  }
];
const indicesMap = computed(() =>
  indicesArray.reduce((map, index) => {
    map[index.id] = index;
    return map;
  }, {})
);

const indexSearchResults = computed(() =>
  searchResults.value.map(s => {
    const indexId = s.request_params.collection_name;
    const shortcutLabel = indicesMap.value[indexId];
    return {
      ...s,
      ...shortcutLabel,
      indexId: s.request_params.collection_name
    };
  })
);

const anyResults = computed(() => indexSearchResults.value.some(s => s.hits.length > 0));

// SELECTED COLLECTION
const selectedCollection = ref(null);
provide('selectedCollection', selectedCollection);

function setSelectedCollection(collection) {
  if (collection == 'recent_searches') {
    selectedCollection.value = collection;
    return;
  }

  // deselect collection
  if (!collection) {
    selectedCollection.value = collection;
    handleSearch(currentRefinement.value);
    return;
  }

  const shortcut = indicesMap.value[collection]?.shortcut;
  handleSearch(`${shortcut} ${currentSearch.value}`);
  searchbox.value.focus();
}
provide('setSelectedCollection', setSelectedCollection);

// RECENT SEARCHES
const recentSearches = useLocalStorage('global_recent_searches', []);
watchDebounced(
  currentSearch,
  () => {
    if (currentSearch.value.trim().length >= 3) recentSearches.value.unshift(currentSearch.value.trim());
  },
  { debounce: 1000 }
);

function deleteRecentSearch(search) {
  recentSearches.value = recentSearches.value.filter(s => s != search);
}

const showRecentSearches = computed(() => recentSearches.value.length && !currentSearch.value);

// FOCUS HANDLING
const focusItems = ref([]);
const activeItem = ref(null);
provide('activeItem', activeItem);

watch(indexSearchResults, setFocusItems, { deep: true });
watch(selectedCollection, setFocusItems);
watch(recentSearches, setFocusItems, { deep: true });
watch(showRecentSearches, setFocusItems);

function setFocusItems() {
  focusItems.value = [];

  if (showRecentSearches.value) {
    const recentSearchesCollection = 'recent_searches';
    focusItems.value = [recentSearchesCollection].concat(
      recentSearches.value
        .map(s => `${recentSearchesCollection}:${s}`)
        .slice(0, selectedCollection.value ? recentSearches.value.length : 3)
    );
  }

  const indices = indexSearchResults.value;
  if (indices) {
    focusItems.value.push(
      ...indices.flatMap(index =>
        [index.indexId].concat(!currentSearch.value ? [] : index.hits.map(hit => `${index.indexId}:${hit.id}`))
      )
    );
  }

  if (selectedCollection.value) {
    focusItems.value = focusItems.value.filter(i => i.startsWith(selectedCollection.value));
  }
}

const updateActiveItem = direction => {
  const itemCount = focusItems.value.length;
  const currentIndex = focusItems.value.indexOf(activeItem.value);
  const nextIndex = (currentIndex + direction + itemCount) % itemCount;
  activeItem.value = focusItems.value[nextIndex];

  const activeCollection = activeItem.value.split(':')[0];
  const collectionElement = document.getElementById(activeCollection);
  if (collectionElement) {
    collectionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
};

onKeyStroke('ArrowDown', () => updateActiveItem(1));
onKeyStroke('ArrowUp', () => updateActiveItem(-1));

// MANAGE SELECTION
onKeyStroke('Enter', () => {
  if (activeItem.value && !activeItem.value.includes(':')) {
    setSelectedCollection(activeItem.value == selectedCollection.value ? null : activeItem.value);
  } else {
    handleSelection(activeItem.value);
  }
});

function handleSelection(item) {
  activeItem.value = item || focusItems.value.filter(i => i.includes(':'))[0];

  if (activeItem.value.includes('recent_searches')) currentSearch.value = activeItem.value.split(':')[1];
  else handleRouting();
}

function handleRouting() {
  const [type, itemUid] = activeItem.value.split(':');
  if (type === 'coins') {
    router.visit(getHit(type, itemUid).tab.url);
  } else if (type === 'companies') {
    router.visit(`/company/${itemUid}`);
  } else if (type === 'pages') {
    router.visit(getHit(type, itemUid).url);
  } else if (type === 'news') {
    window.open(getHit(type, itemUid).link, '_blank');
  } else if (type === 'widget_templates') {
    localStorage.setItem('showComponentLibraryModal', 'true');
    localStorage.setItem('selectedWidgetTemplate', itemUid);
    router.visit('/dashboard');
  } else if (type === 'datasets') {
    localStorage.setItem('selectedDataSourceId', itemUid);
    router.visit(`/dashboard/dataset-library`);
  } else if (type === 'dashboards') {
    let hit = getHit(type, itemUid);
    hit = { id: parseInt(hit.id), label: hit.title };
    localStorage.setItem('selectedDashboard', JSON.stringify(hit));
    router.visit(`/dashboard`);
  }
  toggleOpen();
}

function getHit(type, itemUid) {
  const newsIndex = indexSearchResults.value.find(i => i.id == type);
  return newsIndex.hits.find(p => p.id == itemUid);
}

function submitSupportRequest() {
  $http
    .post('/feedback', {
      feedback: `This user had no results for \`"${currentSearch.value}"\` in global search and wants support added for it.`
    })
    .then(response => {
      flashMessage({
        type: 'success',
        message: `Successfully requested support for "${currentSearch.value}".`
      });
      handleSearch('');
    });
}
</script>
